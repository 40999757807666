<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Create new account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="displayIntro">
                    <v-row justify="center">
                        <p class="headline font-weight-light text-center">We use <strong>LoginShield</strong> to protect your account.</p>
                    </v-row>
                    <v-row justify="center">
                        <p class="body-1 font-weight-light text-center">Login once now to finish creating your account.</p>
                    </v-row>
                    <v-row justify="center" class="pb-5">
                        <v-btn elevation="6" class="blue white--text" @click="login">
                            Continue
                        </v-btn>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
                <!-- <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="isRegistrationError">
                    <v-row justify="center" class="pb-5">
                        <p class="headline font-weight-light text-center red--text">We had trouble with this request, please try again later</p>
                    </v-row>
                </v-card> -->
        </v-col>
    </SectionLayout>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@/client';
import SectionLayout from '@/components/SectionLayout.vue';

const client = new Client();

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            displayIntro: false,
            displayLogin: false,
            interactionId: null,
            isRegistrationError: false,
            email: null,
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        async init() {
            if (this.isAuthenticated) {
                // TODO: logout
            }
            this.$store.commit('loading', { loadInteraction: true });
            const { type, /* next, */ state } = await client.interaction.get(this.interactionId);
            this.$store.commit('loading', { loadInteraction: false });
            console.log('CreateAccountLogin.vue: init: interaction type: %s state: %o', type, state);
            if (type !== 'create_account') {
                this.$router.replace('/');
                return;
            }
            this.email = state.email;
        },
        async register() {
            console.log('CreateInteractionLogin.vue: register');
            this.$store.commit('loading', { editInteraction: true });
            const response = await client.interaction.edit(this.interactionId, { action: 'register-loginshield-user' });
            this.$store.commit('loading', { editInteraction: false });
            // redirect method
            if (response.forward) {
                // redirect user to loginshield.com for registration
                window.location = response.forward;
            }
            if (response.error) {
                this.isRegistrationError = true;
            }
        },
        async login() {
            await this.register();
            this.$router.push({ path: '/login', query: { mode: 'activate-loginshield', i: this.interactionId } });
        },
    },

    created() {
        console.log('created'); // : isAuthenticated: %o', this.isAuthenticated);
        this.interactionId = this.$route.query.i;
        const { step } = this.$route.query;
        switch (step) {
        case 'login':
            this.displayLogin = true;
            break;
        case 'intro':
        default:
            this.displayIntro = true;
            break;
        }
    },

    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
